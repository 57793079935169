import { useContext, useEffect, useState } from 'react';

import {
  Radio,
  FormLabel,
  RadioGroup,
  FormControl,
  FormControlLabel,
  ChangeDescription
} from './styles';
import ChangeModal from './components/ChangeModal';

import { ResellerPaymentMethod } from '../../../../types';
import { useCheckout } from 'views/Review/hooks/useCheckoutContext';
import { trackEvent } from 'config/services/adjustTracking';
import JeittoModal from './components/JeittoModal';
import AleloModal from './components/AleloModal';
import InAppModal from './components/InAppModal';
import PicpayModal from './components/PicpayModal';
import { eventAddPaymentInfo } from 'config/services/gtmEvents';
import { SourceIntegrationContext } from 'providers/source-integration-context/source-integration-context';
import { IProductsState } from 'providers/chosen-products-context/chosen-products-contex';
import PixUserDataModal from './components/PixUserDataModal';

type Props = {
  purchaseTotal: number;
  paymentMethods: ResellerPaymentMethod[];
  couponCode: string;
  products: IProductsState[];
  items: string;
};

export function PaymentMethods(props: Props) {
  const { paymentMethods, purchaseTotal, couponCode, items, products } = props;

  const { integration } = useContext(SourceIntegrationContext);
  const sourceIntegration = integration.source;

  const { paymentId, change, setChange, setPaymentId, setFormIsValid } =
    useCheckout();

  const [changeDescription, setChangeDescription] = useState('');

  const [modalOpen, setModalOpen] = useState(false);

  const [modalJeittoOpen, setModalJeittoOpen] = useState(false);

  const [modalAleloOpen, setModalAleloOpen] = useState(false);
  const [modalInAppOpen, setModalInAppOpen] = useState(false);
  const [modalPicpayOpen, setModalPicpayOpen] = useState(false);
  const [modalPixUserDataOpen, setModalPixUserDataOpen] = useState(false);

  const [defaultOption] = useState(
    paymentMethods.find((paymentMethod) => paymentMethod.name === 'Crédito')
  );

  useEffect(() => {
    setFormIsValid(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setChangeDescription(
      change === '0.0' ? 'Não precisa de troco' : `Troco para ${change}`
    );

    if (paymentId === '') {
      setPaymentId(defaultOption?.id || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [change]);

  function handleChangeDescription(paymentMethod: ResellerPaymentMethod) {
    if (paymentMethod.type === 'MONEY') {
      return (
        <>
          {paymentMethod.name}
          {change && paymentMethod.type === 'MONEY' && (
            <ChangeDescription>{changeDescription}</ChangeDescription>
          )}
        </>
      );
    }

    if (paymentMethod.name.toLocaleLowerCase() === 'picpay') {
      return <>Pagar com Picpay</>;
    }

    return <span>{paymentMethod.name}</span>;
  }

  function openPaymentModal(paymentId: string) {
    setFormIsValid(false);

    const paymentMethod = paymentMethods.find(
      (paymentMethod) => paymentMethod.id === paymentId
    );

    if (!paymentMethod) return;

    if (paymentMethod.type === 'PIX') {
      setFormIsValid(false);
      setModalPixUserDataOpen(true);

      return;
    }

    if (paymentMethod.type === 'MONEY') {
      setFormIsValid(true);
      setModalOpen(true);

      return;
    }

    if (paymentMethod.name.toLocaleLowerCase() === 'jeitto') {
      trackEvent({
        eventToken: 'Selecionou Pagamento Com Jeitto',
        sourceIntegration
      });
      setFormIsValid(false);
      setModalJeittoOpen(true);

      return;
    }

    if (
      paymentMethod.slug.toLocaleLowerCase() === 'alelo' ||
      paymentMethod.slug.toLowerCase().replace(' ', '') ===
        'alelomultibenefícios'
    ) {
      trackEvent({ eventToken: 'Pagar com Alelo', sourceIntegration });
      setFormIsValid(false);
      setModalAleloOpen(true);
      return;
    }

    const inapp_slugs = ['inapp', 'inapp_debit', 'bradesco', 'elo', 'vr'];

    if (inapp_slugs.includes(paymentMethod.slug.toLocaleLowerCase())) {
      setFormIsValid(false);
      setModalInAppOpen(true);

      return;
    }

    if (paymentMethod.slug.toLocaleLowerCase() === 'picpay') {
      setFormIsValid(false);
      trackEvent({ eventToken: 'Pagar com Picpay', sourceIntegration });
      setModalPicpayOpen(true);

      return;
    }

    return setFormIsValid(true);
  }

  function handleRadioOption(event: React.ChangeEvent<HTMLInputElement>) {
    setPaymentId(event.target.value);

    const paymentMethod = paymentMethods.find(
      (paymentMethod) => paymentMethod.id === event.target.value
    );

    if (!paymentMethod) return;

    trackEvent({
      sourceIntegration,
      eventToken: 'Escolheu F. Pagamento'
    });

    eventAddPaymentInfo(
      couponCode,
      'BRL',
      products,
      paymentMethod.name,
      purchaseTotal,
      items
    );

    setChange('');
    setChangeDescription('');
  }

  const paymentMethodsInApp = paymentMethods.filter(
    (payment) => payment.is_inapp === true
  );

  const paymentMethodsDelivery = paymentMethods.filter(
    (payment) => payment.is_inapp === false
  );

  return (
    <>
      <FormControl>
        <RadioGroup
          name="payment-methods-buttons-group"
          aria-labelledby="forma-de-pagamento"
          onChange={(event) => handleRadioOption(event)}
        >
          {!!paymentMethodsInApp.length && (
            <>
              <FormLabel id="payment-methods-buttons-group-label">
                Pague pelo aplicativo:
              </FormLabel>

              {paymentMethodsInApp.map((paymentMethod) => (
                <FormControlLabel
                  control={<Radio />}
                  key={paymentMethod.id}
                  value={paymentMethod.id}
                  label={handleChangeDescription(paymentMethod)}
                  onClick={() => openPaymentModal(paymentMethod.id)}
                />
              ))}
            </>
          )}

          {!!paymentMethodsDelivery.length && (
            <>
              <FormLabel id="payment-methods-buttons-group-label">
                Pague na entrega:
              </FormLabel>

              {paymentMethodsDelivery.map((paymentMethod) => (
                <FormControlLabel
                  control={<Radio />}
                  key={paymentMethod.id}
                  value={paymentMethod.id}
                  label={handleChangeDescription(paymentMethod)}
                  onClick={() => openPaymentModal(paymentMethod.id)}
                />
              ))}
            </>
          )}
        </RadioGroup>
      </FormControl>

      <ChangeModal
        open={modalOpen}
        purchaseTotal={purchaseTotal}
        onClose={() => setModalOpen(false)}
      />

      <JeittoModal
        open={modalJeittoOpen}
        onClose={() => setModalJeittoOpen(false)}
      />

      <AleloModal
        open={modalAleloOpen}
        onClose={() => setModalAleloOpen(false)}
      />

      <InAppModal
        open={modalInAppOpen}
        onClose={() => setModalInAppOpen(false)}
      />

      <PicpayModal
        open={modalPicpayOpen}
        onClose={() => setModalPicpayOpen(false)}
      />

      <PixUserDataModal
        open={modalPixUserDataOpen}
        onClose={() => setModalPixUserDataOpen(false)}
      />
    </>
  );
}
