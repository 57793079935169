import { ReactNode, useContext, useEffect, useState } from 'react';
import { Order } from 'types';
import { OrderContext } from './order.context';
import { LeadDataContext } from 'providers/lead-data-context/lead-data.context';
import { GetOrderDataUseCase } from 'use-cases/orders/get-order-data.use-case';
import { useNavigate } from 'react-router-dom';
import { GetPendingOrdersUseCase } from 'use-cases/orders/get-pending-orders.use-case';
import { differenceInHours } from 'date-fns';
import { readStorage } from 'hooks/useLocalStorage';

interface OrderProviderProps {
  children: ReactNode;
}

export function OrderProvider({ children }: OrderProviderProps) {
  const [order, setOrder] = useState<Order | null>(null);
  const navigate = useNavigate();
  const { leadData } = useContext(LeadDataContext);

  const canceledStatus = [
    'user_cancelled',
    'admin_cancelled',
    'driver_canceled',
    'user_cancelled',
    'admin_cancelled',
    'driver_cancelled',
    'manager_cancelled',
    'accept_expired'
  ];

  function checkTimeIsExpired(order: Order) {
    if (order?.finished_at || order?.canceled_at) {
      const today = new Date();
      const HOURS_DIFFERENCE_TO_CANCEL_RATING = 24;
      const finishedOrCanceledAt = (
        order?.finished_at !== null ? order?.finished_at : order?.canceled_at
      )
        .split(' ')[0]
        .split('/');

      const finishedOrCanceledDate =
        finishedOrCanceledAt && new Date(finishedOrCanceledAt.toString());

      const differenceTodayAndFinishedOrCanceled = differenceInHours(
        finishedOrCanceledDate,
        today
      );

      const isExpired =
        differenceTodayAndFinishedOrCanceled >=
        HOURS_DIFFERENCE_TO_CANCEL_RATING;

      return isExpired;
    }
  }

  async function getOrderData(orderId: string) {
    let hasPendingOrder = false;
    await new GetPendingOrdersUseCase()
      .handle()
      .then((data) => {
        if (data[0]) {
          setOrder(data[0]);
          navigate('/order-tracking');
          hasPendingOrder = true;
        }
      })
      .catch((e) => {
        console.log('getPendingOrdersErr', e);
      });

    if (hasPendingOrder === false) {
      await new GetOrderDataUseCase()
        .handle(orderId)
        .then((data) => {
          const resellerViewCheckpoint = readStorage('resellerViewCheckpoint');
          if (
            data &&
            canceledStatus.includes(data?.status) &&
            !checkTimeIsExpired(data) &&
            resellerViewCheckpoint !== 'true'
          ) {
            setOrder(data);
            navigate('/order-cancelled');
          } else if (
            data &&
            data?.status === 'finish' &&
            !data?.rated &&
            !checkTimeIsExpired(data) &&
            resellerViewCheckpoint !== 'true'
          ) {
            setOrder(data);
            navigate('/order-rating');
          }
        })
        .catch((e) => {
          console.log('getOrderErr', e);
        });
    }
  }

  useEffect(() => {
    (leadData?.last_order_id || leadData?.token) &&
      getOrderData(leadData.last_order_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadData]);

  return (
    <OrderContext.Provider value={{ order, setOrder }}>
      {children}
    </OrderContext.Provider>
  );
}
