import {
  Card,
  CardContent,
  Skeleton,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { formatPrice } from 'helpers/formatPrice';

interface TotalPriceProps {
  totalPrice?: number | null;
  isLoading?: boolean;
}

export function TotalPrice(props: TotalPriceProps) {
  const { palette } = useTheme();
  const { totalPrice, isLoading } = props;

  return (
    <Card
      variant="custom"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <Stack>
        <CardContent
          sx={{
            px: '12px',
            py: '8px !important',
            justifyContent: 'space-between',
            display: 'flex',
            pt: '14px',
            pb: '14px !important',
            alignItems: 'center'
          }}
        >
          <Typography
            sx={{ fontSize: '12px' }}
            variant="caption"
            lineHeight={'11px'}
            color={palette.grey[900]}
            fontWeight={'600'}
          >
            Valor final do pedido:
          </Typography>
          <Typography
            sx={{ fontSize: '12px' }}
            variant="caption"
            lineHeight={'11px'}
            color={palette.grey[800]}
            fontWeight={'600'}
          >
            {isLoading ? (
              <Skeleton
                variant="text"
                width={'40px'}
                height={'15px'}
                sx={{ p: '0' }}
              />
            ) : totalPrice ? (
              formatPrice(totalPrice)
            ) : (
              'R$ 0,00'
            )}
          </Typography>
        </CardContent>
      </Stack>
    </Card>
  );
}
